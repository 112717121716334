@use "./variables" as *;

.card-item {
    height: 100%;
    width: 100%;
    max-width: 480px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;

    &:hover {
        background-color: rgba($light-grey, 0.5);
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.20);
    }
}

.card-item-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.8rem 0;
    min-height: 120px;

    h3 {
        font-weight: 600;
        font-size: 1.1rem;
        color: $dark;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }

    p {
        color: $grey;
        font-size: 1rem;
    }

    .info {
        margin-top: auto;
    }
}

.card-item-header {
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;


        &:not(.image-loaded) {
            opacity: 1;
            background-color: #F6F6F6;
            color: #F6F6F6;
        }
    }

    &::before {
        content: "";
        display: block;
        padding-top: min(200px, 56.25%); /* 16:9 aspect ratio */
    }
}

.card-item-footer {
    margin-top: 0.75rem;
    display: flex;
    justify-content: space-between;
    padding: 0 0.8rem;
}
